import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { useHistory } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let controller = new AbortController();

export function SearchDropdown() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  let timeoutId;

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const handleSearchChange = async (event) => {
    setData(null);
    setSearchValue(event.target.value);
    controller.abort();
    controller = new AbortController();
    await getQuickSearchReselt(event.target.value);
  };

  const clear = () => {
    setData(null);
    setSearchValue("");
  };
  function getQuickSearchReselt(params) {
    const signal = controller.signal;
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("user")).token}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      signal: signal,
      redirect: "follow",
    };

    return fetch(
      `${process.env.REACT_APP_BASE_URL}/Search/SmallSearch?query=${params}&entity=global`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var datas = result[1];
          setData(datas?.results || []);
          setLoading(false);
        }
      })
      .catch((error) => {
        setData([]);
        setLoading(false);
        console.log("error", error);
      });
  }
  useEffect(() => {
    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {!layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {layoutProps.offcanvas &&
        JSON.parse(localStorage.getItem("user"))?.roles.some((v) =>
          [
            "Business Admin",
            "Business User",
            "Business Manager",
            "Business Staff",
            "Business Viewer",
          ].includes(v)
        ) && (
          <Dropdown
            alignRight
            drop="down"
            onToggle={() => {
              setData(null);
              setLoading(false);
              setSearchValue("");
            }}
            id="kt_quick_search_toggle"
            onClick={() => {
              if (document.getElementById("GlobalSearchInput")) {
                document.getElementById("GlobalSearchInput").focus();
              }
            }}
          >
            <Dropdown.Toggle as={DropdownTopbarItemToggler}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="search-panel-tooltip">Quick search</Tooltip>
                }
              >
                <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
              <div
                id="kt_quick_search_dropdown"
                className={clsx("quick-search quick-search-dropdown", {
                  "quick-search-has-result": data && data.length,
                })}
              >
                <form
                  autoComplete={false}
                  className="quick-search-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (
                      searchValue === "" ||
                      searchValue === null ||
                      searchValue === undefined
                    ) {
                      return;
                    }
                    history.push(`/search?entity=&key=${searchValue}`);
                  }}
                >
                  <div className="input-group">
                    <div className={`input-group-prepend`}>
                      <span
                        className="input-group-text"
                        onClick={() => {
                          history.push(`/search?entity=&key=${searchValue}`);
                        }}
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <input
                      type="text"
                      id="GlobalSearchInput"
                      autoFocus={true}
                      placeholder="Search Anything"
                      value={searchValue}
                      onChange={handleSearchChange}
                      className="form-control"
                    />

                    <div
                      className={`input-group-append ${
                        loading ? "spinner spinner-sm spinner-primary" : ""
                      }")}`}
                    >
                      <span className="input-group-text">
                        <i
                          style={{
                            display:
                              loading && searchValue && searchValue.length > 0
                                ? "none"
                                : "flex",
                          }}
                          onClick={clear}
                          className="quick-search-close ki ki-close icon-sm text-muted"
                        />
                      </span>
                    </div>
                  </div>
                </form>
                <SearchResult
                  data={data}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
    </>
  );
}
