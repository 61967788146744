import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

const Payment = ({ handleSubmit, formik }) => {
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm handleSubmit2={handleSubmit} formik={formik} />
        </Elements>
      )}
    </>
  );
};

export default Payment;
