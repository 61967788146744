import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const Previews = (props) => {
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 10,
    border: `1px dashed ${props?.isLoadingDs === false ?
      (props?.OTCStatut === true ?
        (props?.ChangeSteps === true ? (!!props?.color === true ? props?.color : "#EC520B") : "#EC520B")
        : "#EC520B")
      : ""}`,
    marginBottom: 8,
    marginRight: 8,
    width: "100%",
    padding: 4,
    boxSizing: "border-box",
  };
 
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      props.setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = props.files.map((file, index) => (
    <div className="d-flex flex-wrap">
      <div
        className="image-input image-input-outline mx-5 mb-5"
        id="kt_image_4"
        style={{
          backgroundImage: `url(${file.preview})`,
        }}
      >
        <div
          className="image-input-wrapper"
          style={{
            backgroundImage: `url(${file.preview})`,
          }}
        />
        <span
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          title=""
          data-original-title="Remove avatar"
        >
          <i
            className="ki ki-bold-close icon-xs text-danger"
            onClick={() => props.onDelete(index)}
          />
        </span>
      </div>
    </div>
  ));

  useEffect(() => {
    return () =>
      props.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container d-flex flex-column" style={thumb}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="pt-5 position-relative d-flex justify-content-center">
          <img src={toAbsoluteUrl("/media/logos/drag.png")} alt="drag" />
        </div>
        <p className="pb-5 px-10 pt-3 text-center">
          Drag and drop choose file to upload your files.
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};

export default Previews;
