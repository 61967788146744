/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GoogleMapContainer from "./GoogleMapComp";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export function LiveTracking({ OrdersList, className }) {
  const history = useHistory();

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0 position-relative overflow-hidden">
        <div
          className="card-body d-flex flex-column"
          style={{ height: "600px" }}
        >
          <GoogleMapContainer
            LoadsPositionList={OrdersList}
            isDashboard={true}
            history={history}
          />
        </div>
      </div>
    </div>
  );
}
