import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { ModelStateMessage } from "../../../_metronic/_helpers/apiHelpers";
import Previews from "./Previews";
import {
  ConvertToDateGMT,
  getFromatdate,
} from "../../../_metronic/_helpers/GlobalHelpers";
import moment from "moment";
import { GoogleMap } from "../../../_metronic/_partials/dashboards/GoogleMapComp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TagInput from "./TagInput";
import Payment from "../stripe/Payment";
import clsx from "clsx";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ReactSelect from "react-select";
import classNames from 'classnames';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const customStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    padding: "0.6rem",
    backgroundColor: "#f3f6f9",
    borderColor: "#f3f6f9",
  }),
};
const Schema = [
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  }),
  Yup.object({
    address1: Yup.string().required("Address 1 is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Postal code is required"),
  }),
  Yup.object({}),
  Yup.object({
    deliveryAppointmentToDate: Yup.string().required(
      "Delivery time is required"
    ),
    deliveryAppointmentDate: Yup.string().required("Delivery date is required"),
  }),
  Yup.object({}),
  Yup.object({}),
  Yup.object({
    cardName: Yup.string().required("Card name is required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "Please accept terms and conditions"
    ),
  }),
];

export default function RxDropPage() {
  const [OTCStatut, setOTCStatut] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [filesInsurence, setFilesInsurence] = useState([]);
  const [tipPerc, setTipPerc] = useState(0);
  const [tip, setTip] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [step, setStep] = useState(1);
  const totalSteps = (OTCStatut === true ? 7 : 6);
  const [orderNumber, setOrderNumber] = useState("");
  const [countries, setCountries] = useState([]);
  const [pharmacyInfo, setPharmacyInfo] = useState([]);
  const [termsInfo, setTermsInfo] = useState([]);
  const [charges, setCharges] = useState([]);
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { id } = useParams();
  const [categories, setcategories] = useState(true);
  const [categoryId, setcategoryId] = useState(null);
  const [DataProducts, setDataProducs] = useState([]);
  const [productDetail, setproductDetail] = useState(null);
  const [DataCategories, setDataCategories] = useState([]);
  const [isLoadingCat, setisLoadingCat] = useState(false);
  const [isLoadingDET, setisLoadingDET] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [ChangeSteps, setChangeSteps] = useState(false);
  const [color, setcolor] = useState(null);
  const [Logo, setLogo] = useState(null);
  const customClassName = null;
  const [Colorcss, setColorcss] = useState(null);

  const [pagination, setPagination] = useState({
    count: 0,
    pageCount: 0,
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    isFirstPage: true,
    isLastPage: true,
  });
  const [DataFilter, setDataFilter] = useState([]);
  
  useEffect(() => {
    if (OTCStatut === true) {
      if (ChangeSteps === true) {
        setColorcss(true)
      } else if (ChangeSteps === true) {
        setColorcss(false)
      }
    } else if (OTCStatut === false) {
      setColorcss(false)
    }
  }, [ChangeSteps, OTCStatut]);
  const getcategories = () => {
    setisLoadingCat(true)
    fetch(`${BASE_URL}/RxDrop/product/category?pharmacyId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setisLoadingCat(false)
          setDataCategories(data[1]);
          const transformedCategories = [
            { label: "All", value: null },
            ...data[1]?.map(category => ({ label: category.name, value: category.id }))
          ];
          setDataFilter(transformedCategories)
        }
      })
      .catch((error) => {
        setisLoadingCat(false)
        console.log(error);
      });
  };
  const getProducts = (categoryId, searchProducts, pageNumber) => {
    setIsLoading(true)

    fetch(`${BASE_URL}/rxdrop/products?pharmacyId=${id}&categoryId=${categoryId !== null ? categoryId : ""}&search=${searchProducts}&pageNumber=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setDataProducs(data[1]?.products);
          setPagination({
            count: data[1]?.pagination?.count,
            pageCount: data[1]?.pagination?.pageCount,
            pageNumber: data[1]?.pagination?.pageNumber,
            pageSize: data[1]?.pagination?.pageSize,
            totalItemCount: data[1]?.pagination?.totalItemCount,
            hasPreviousPage: data[1]?.pagination?.hasPreviousPage,
            hasNextPage: data[1]?.pagination?.hasNextPage,
            isFirstPage: data[1]?.pagination?.isFirstPage,
            isLastPage: data[1]?.pagination?.isLastPage,
          });
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };
  const getProductDetails = (ProductId) => {
    setisLoadingDET(true)
    fetch(`${BASE_URL}/rxdrop/products/${ProductId}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setisLoadingDET(false)
          setproductDetail(data[1]);
        }
      })
      .catch((error) => {
        setisLoadingDET(false)
        console.log(error);
      });
  };
  const [isLoadingDs, setisLoadingDs] = useState(true);

  const GetStatut = () => {
    setisLoadingDs(true)
    fetch(`${BASE_URL}/RxDrop/products/status/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setOTCStatut(data[1]?.status)
          setisLoadingDs(false)
        }
      })
      .catch((error) => {
        setisLoadingDs(false)
        console.log(error);
      });
  };
  const GetStatutStep = () => {
    fetch(`${BASE_URL}/Stores/products/step?pharmacyId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          if (data[1]?.status === null) {
            setChangeSteps(false)
          } else {
            setChangeSteps(data[1]?.status)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getcategories()
    GetStatutStep()
    GetStatut()
  }, [])
  const [categoriesTyp, setcategoriesTyp] = useState(null);
  const transformArray = (originalArray) => {
    return originalArray.map((pro) => ({
      productId: pro?.id,
      quantity: pro?.quantity || 1, // default quantity to 1 if not present
    }));
  };
  const [orders, setorders] = useState([]);
  const [totalOTC, setTotalOTC] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [OpenProduct, setOpenProduct] = useState(null);
  useEffect(() => {
    if (!showDetail) {
      setproductDetail(null)
    }
  }, [showDetail])
  const handleCloseDetail = () => setShowDetail(false);
  const handleShowDetail = () => setShowDetail(true);
  const updateOrders = (updatedOrders) => {
    // Calculate total
    const newTotal = updatedOrders.reduce(
      (acc, order) => acc + order.quantity * order.price,
      0
    );
    setTotalOTC(parseFloat(newTotal.toFixed(2)));
  };
  useEffect(() => {
    if (!!orders) {
      updateOrders(orders)
      formik.setFieldValue("orderProducts", transformArray(orders))
    }
  }, [orders])
  const onDelete = (index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };
  const onDeleteInsurence = (index) => {
    let temp = [...filesInsurence];
    temp.splice(index, 1);
    setFilesInsurence(temp);
  };

  function addHours(h) {
    var a = new Date();
    a.setHours(a.getHours() + h);
    return a;
  }
  const handleGetCharges = () => {
    setLoading(true);
    fetch(`${BASE_URL}/RxDrop/GetCharges`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pharmacyId: id,
        address1: formik.values.address1,
        address2: formik.values.address2,
        city: formik.values.city,
        country: formik.values.country,
        state: formik.values.state,
        zipCode: formik.values.zipCode,
        buzzCode: formik.values.buzzCode,
        unitNumber: formik.values.unitNumber,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          formik.setFieldValue(
            "tip",
            false
          );
          setTip(null)
          setTipPerc(0)
          setCharges(data[1]);
          formik.setFieldValue("amount", data[1].totalWithTax);
          formik.setFieldValue("charge", data[1].totalWithTax);
          formik.setFieldValue("distance", data[1].distance);
          formik.setFieldValue("distanceMiles", data[1].distanceMiles);
          formik.setFieldValue("driving", data[1].driving);
        } else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextStep = () => {
    setcategories(true)
    if(orderType === "NoThanks"){
      if((OTCStatut === true) && (ChangeSteps === true) && (step === 3)){
        handleGetCharges()
        setStep(6)
       } else if((OTCStatut === true) && (ChangeSteps === false) && (step === 2)) {
        handleGetCharges()
        setStep(5)
       }else{
        setStep(step + 1);
       }
    }else{
      setStep(step + 1);
    }
  };
  const handlePrevStep = () => {
    setcategories(true)
    if(orderType === "NoThanks"){
      if((OTCStatut === true) && (ChangeSteps === true) && (step === 6)){
        setStep(3)
       } else if((OTCStatut === true) && (ChangeSteps === false) && (step === 5)) {
        setStep(2)
       }else{
        setStep(step - 1);
       }
    }else{
      setStep(step - 1);
    }
  };

  const getCountires = () => {
    fetch(`${BASE_URL}/Generic/Countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.countries.map((country) => {
            return { value: country.name, label: country.name };
          });
          setCountries(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStates = (code) => {
    fetch(`${BASE_URL}/Generic/States?name=${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.states.map((state) => {
            return { value: state.name, label: state.name };
          });
          setStates(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetPharmacyInfo = () => {
    setIsFetching(true);
    fetch(`${BASE_URL}/RxDrop/GetInfos?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setPharmacyInfo(data[1]);
          setcolor(data[1]?.storeFrontColor)
          setLogo(data[1]?.storeFrontLogo)
          setIsFetching(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  };
  const GetTermsInfo = () => {
    fetch(`${BASE_URL}/Generic/Setting/ConsentRX`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setTermsInfo(data[1]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetPharmacyInfo();
    getCountires();
    GetTermsInfo();
    getStates("Canada");
  }, []);

  const formik = useFormik({
    initialValues: {
      pharmacyId: id,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      country: "Canada",
      state: "Ontario",
      zipCode: "",
      buzzCode: "",
      unitNumber: "",
      rxNumber: "",
      isNewPrescription: true,
      coupon: "",
      amount: 0,
      compounding: false,
      tip: false,
      tipAmount: 0,
      authorizedPerson: [],
      deliveryAppointmentDate: new Date(),
      deliveryAppointmentToDate: addHours(4),
      notes: "",
      cardName: "",
      cardToken: "",
      cardId: "",
      distance: 0,
      distanceMiles: 0,
      driving: 0,
      charge: 0,
      termsAndConditions: false,
      orderProducts: []
    },
    validationSchema: Schema[step - (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1)],
    onSubmit: () => {
      if (step < (OTCStatut === true ? 7 : 6)) {
        handleNextStep();
      }
      if (step === (OTCStatut === true ? 7 : 6)) {
        // handleCreateOrder();
        if (formik.values.cardName && formik.values.termsAndConditions) {
          document.getElementById("pay_now").click();
        } else {
          if (formik.values.cardName === "") {
            formik.setFieldError("cardName", "Card name is required");
          }
          if (!formik.values.termsAndConditions) {
            formik.setFieldError(
              "termsAndConditions",
              "Please accept terms and conditions"
            );
          }
        }
      }
    },
  });

  useEffect(() => {
    if (tipPerc != 0) {
      let tipAmount = ((charges.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0)) * tipPerc) / 100;
      let total = charges.totalWithTax + tipAmount + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0)
      formik.setFieldValue("tipAmount", tipAmount);
      formik.setFieldValue("amount", total);
      setCharges({ ...charges, tipAmount: total });
    }
    if (tipPerc == 0) {
      formik.setFieldValue("amount", charges.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0));
      formik.setFieldValue("tipAmount", 0);
      setCharges({ ...charges, tipAmount: 0 });
    }
  }, [tipPerc]);

  useEffect(() => {
    if (tip !== null) {
      let total = charges.totalWithTax + parseFloat(tip) + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0);
      formik.setFieldValue("tipAmount", parseFloat(tip));
      formik.setFieldValue("amount", total);
      setCharges({ ...charges, tipAmount: total });
    }
    if (tip === null && tipPerc == 0) {
      formik.setFieldValue("amount", charges.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0));
      formik.setFieldValue("tipAmount", 0);
      setCharges({ ...charges, tipAmount: 0 });
    }
  }, [tip]);

  useEffect(() => {
    console.log(parseFloat("12.32"))
    if (tip !== null) {
      let total = charges.totalWithTax + parseFloat(tip) + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0) + totalOTC;
      formik.setFieldValue("tipAmount", parseFloat(tip));
      formik.setFieldValue("amount", total);
      setCharges({ ...charges, tipAmount: total });
    }
    if (tip === null && tipPerc == 0) {
      formik.setFieldValue("amount", charges.totalWithTax + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0) + totalOTC);
      formik.setFieldValue("tipAmount", 0);
      setCharges({ ...charges, tipAmount: 0 });
    }
    if (tipPerc != 0) {
      let tipAmount = ((charges.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0)) * tipPerc) / 100;
      let total = charges.totalWithTax + tipAmount + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0)
      formik.setFieldValue("tipAmount", tipAmount);
      formik.setFieldValue("amount", total);
      setCharges({ ...charges, tipAmount: total });
    }
    if (tipPerc == 0) {
      formik.setFieldValue("amount", charges.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0));
      formik.setFieldValue("tipAmount", 0);
      setCharges({ ...charges, tipAmount: 0 });
    }
  }, [totalOTC]);

  const resetForm = () => {
    console.log(orders)
    setorders([]);
    setcategories(true);
    formik.resetForm();
    setFiles([]);
    setFilesInsurence([]);
    setStep(1);
    setOrderType("");
    setTags([]);
  };


  const handlePageChange = (newPageNumber) => {
    setDataProducs(null)
    getProducts(categoryId, search, newPageNumber);
  };
  const handleCreateOrder = (values) => {
    setIsLoading(true);
    let data = { ...values };
    data.deliveryAppointmentDate = ConvertToDateGMT(
      data.deliveryAppointmentDate
    );
    data.deliveryAppointmentToDate = ConvertToDateGMT(
      data.deliveryAppointmentToDate
    );
    fetch(`${BASE_URL}/RxDrop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          if (files.length > 0 || filesInsurence.length > 0) {
            handlePostAttachments(data[1].id);
            setOrderNumber(data[1].code);
          } else {
            setIsLoading(false);
            Swal.fire({
              position: "center",
              title: "Order received successfully!",
              text: "Your will receive a confirmation email shortly.",
              showConfirmButton: true,
              timer: 5000,
            });
            setStep(OTCStatut === true ? 8 : 7);
            setTags([]);
            setOrderNumber(data[1].code);
          }
        } else {
          setIsLoading(false);
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handlePostAttachments = (id) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    fetch(`${BASE_URL}/RxDrop/Attachments?id=${id}`, {
      method: "POST",
      body: formData,
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          if (filesInsurence.length > 0) {
            handlePostAttachmentsInsurence(id);
          } else {
            setIsLoading(false);
            Swal.fire({
              position: "center",
              title: "Order received successfully!",
              text: "Your will receive a confirmation email shortly.",
              showConfirmButton: true,
              timer: 5000,
            });
            setStep(OTCStatut === true ? 8 : 7);
            setTags([]);
          }
        } else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handlePostAttachmentsInsurence = (id) => {
    const formData = new FormData();
    filesInsurence.forEach((file) => {
      formData.append("files", file);
    });
    fetch(`${BASE_URL}/RxDrop/InsuranceCoverage?id=${id}`, {
      method: "POST",
      body: formData,
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            title: "Order received successfully!",
            text: "Your will receive a confirmation email shortly.",
            showConfirmButton: true,
            timer: 5000,
          });
          setStep(OTCStatut === true ? 8 : 7);
          setTags([]);
        } else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (step === (OTCStatut === true ? (ChangeSteps === false ? 3 : 4) : 3)) {
      handleGetCharges();
    }
  }, [step]);
  useEffect(() => {
    if (tags) {
      formik.setFieldValue("authorizedPerson", tags);
    }
  }, [tags]);


  return (
    <div>
      <div className="d-flex flex-column flex-root">
        <div className="mb-0" id="home">
          <section className="subheader py-2 justify-content-center" style={{ backgroundColor: isLoadingDs === false ? (OTCStatut === true ? (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B") : "#EC520B") : "" }}>
            <div className="position-relative overflow-hidden">
              <Container
                fluid
                className="position-relative d-flex align-items-center flex-wrap"
              >
                <Col
                  xl="12"
                  lg="12"
                  md="12"
                  xs="12"
                  className="d-flex flex-column flex-grow-1 mr-3 align-items-center"
                >
                  {isLoadingDs === false ? <>
                    {
                      OTCStatut === true ? (ChangeSteps === true ?
                        (!!Logo === true ? 
                        <div className=" w-85px h-75px  mr-3">
                          <img
                            alt="Logo"
                            className="w-100 h-100"
                            src={toAbsoluteUrl(Logo)}
                            
                          />
                        </div> : <h3 className="display-3 font-weight-bolder text-white">
                          RxDrop
                        </h3>) :
                        <h3 className="display-3 font-weight-bolder text-white">
                          RxDrop
                        </h3>) :
                        <h3 className="display-3 font-weight-bolder text-white">
                          RxDrop
                        </h3>
                    }
                  </> : ""}

                </Col>
              </Container>
            </div>
          </section>
        </div>

        <div className="my-lg-20 my-10 z-index-2">
          <Container>
            {isFetching ? (
              <div className="mb-10 pb-5">
                {" "}
                <Skeleton count={4} height={40} width={450} />
              </div>
            ) : (
              <div>
                <div className="content flex-row-fluid mr-lg-17 mr-0">

                  <Col
                    xl="12"
                    lg="12"
                    md="12"
                    xs="12"
                    className="align-items-center mb-10"
                  >
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50px mr-3">
                        <img
                          alt="Logo"
                          className="max-h-40px"
                          src={toAbsoluteUrl("/media/logos/Logo.svg")}
                        />
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="#"
                          className="text-dark font-weight-bolder  mb-1 display-4"
                        >
                          {pharmacyInfo?.name}
                        </a>
                      </div>
                    </div>
                    <div className="d-flex flex-column font-weight-semibold h6 mb-4 pr-2 mt-10">
                      <a
                        className="d-flex align-items-center text-dark mr-5 mb-4"
                        href="tel:+14165551234"
                      >
                        <span className="svg-icon menu-icon mr-3">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Active-call.svg"
                            )}
                          />
                        </span>
                        {pharmacyInfo?.phoneNumber}
                      </a>
                      <a
                        className="d-flex align-items-center text-dark  mr-5 mb-4"
                        href="mailto:info@healthpluspharmacy.com"
                      >
                        <span className="svg-icon menu-icon mr-3">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-at.svg"
                            )}
                          />
                        </span>
                        {pharmacyInfo?.email}
                      </a>
                      <a
                        className="d-flex align-items-center text-dark  mb-4"
                        href="#"
                      >
                        <span className="svg-icon menu-icon mr-3">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Map/Marker1.svg"
                            )}
                          />
                        </span>
                        {pharmacyInfo?.address1}
                        {pharmacyInfo?.address2 && ","}{" "}
                        {pharmacyInfo?.address2 && pharmacyInfo?.address2}
                        <br />
                        {pharmacyInfo?.city}, {pharmacyInfo?.state}{" "}
                        {pharmacyInfo?.zipCode}, {pharmacyInfo?.country}
                      </a>
                      {/* <a
                    className="d-flex align-items-center text-dark  mb-4"
                    href="#"
                  >
                    <span className="svg-icon menu-icon mr-3">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Time-schedule.svg"
                        )}
                      />
                    </span>
                    MON-FRI: 09:00 AM - 07:00 PM
                  </a> */}
                    </div>
                  </Col>
                </div>
              </div>

            )}
          </Container>
          <Container>
            {step === (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1) && (
              <Col xl="12" lg="12" md="12" xs="12" className="mb-10">
                <Form>
                  <Form.Group>
                    <Form.Label className="h1 font-weight-semibold mb-5">
                    You have any prescriptions needs?
                    </Form.Label>
                    <div className="d-flex">
                    <div className="radio-inline">
                      <label className="radio" >
                        <input
                          style={{
                            color: isLoadingDs === false ?
                              (OTCStatut === true ?
                                (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                : "#EC520B")
                              : "",
                          }}
                          type="radio"
                          name="radios2"
                          checked={orderType === "newPrescription"}
                          value={formik.values.IsNewPrescription}
                          onChange={() => {
                            formik.setFieldValue("IsNewPrescription", true);
                            setOrderType("newPrescription");
                          }}
                          disabled={step > (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1)}
                        />
                        <span></span>
                        New Prescription
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="radios2"
                          checked={orderType === "refill"}
                          value={formik.values.IsNewPrescription}
                          onChange={() => {
                            formik.setFieldValue("IsNewPrescription", false);
                            setOrderType("refill");
                          }}
                          disabled={step > (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1)}
                        />
                     
                        <span></span>
                        Refill
                      </label>
                      {OTCStatut === true ?
                      <label className="radio">
                      <input
                        type="radio"
                        name="radios2"
                        checked={orderType === "NoThanks"}
                        value={formik.values.IsNewPrescription === null ?true :false}
                        onChange={() => {
                          formik.setFieldValue("IsNewPrescription", null);
                          setOrderType("NoThanks");
                        }}
                        disabled={step > (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1)}
                      />
                   
                      <span></span>
                      No thank You
                    </label>
                      :""}
                    </div>
                    {/* <div className="checkbox-inline ">
                      <label className="checkbox  custom-checkbox">
                        <input
                          className=" w-30px h-30px "
                          type="checkbox"
                          defaultValue
                          name="compounding"
                          value={formik.values.compounding}
                          onChange={formik.handleChange}
                        // checked={x?.isFlat === true ? true : false}
                        />
                        No
                      </label>
                    </div> */}
                    </div>
                   
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="compounding">
                    <div className="checkbox-inline ">
                      <label className="checkbox  custom-checkbox">
                        <input
                          className=" w-30px h-30px "
                          type="checkbox"
                          defaultValue
                          name="compounding"
                          value={formik.values.compounding}
                          onChange={formik.handleChange}
                        // checked={x?.isFlat === true ? true : false}
                        />
                        <span></span>
                        Compound Prescription
                      </label>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            )}

            <Col xl="12" lg="12" md="12" xs="12" className="mb-10">
              {step === (OTCStatut === true ? (ChangeSteps === false ? 2 : 3) : 2) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      className="symbol symbol-30 symbol-circle  mr-5"
                      onClick={() => setStep(1)}
                      style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                      }}
                    >
                      <span className="symbol-label" style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                          color:"white"
                      }}>1</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                      <h1 className="text-dark font-weight-semibold mb-0">
                        Contact Information
                      </h1>
                    </div>
                  </div>
                </>
              )}

              {orderType && step === (OTCStatut === true ? (ChangeSteps === false ? 1 : 2) : 1) && (
                <>
                  <h1 className="font-weight-semibold mb-5">
                    Contact Information
                  </h1>
                  <Form className="px-5">
                    <Form.Group className="mb-3" controlId="firstName">
                      <Form.Label className="required">First Name</Form.Label>{" "}
                      <span className="text-danger">*</span>
                      <Form.Control
                        type="text"
                        placeholder="Type here"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      <div className="text-primary">
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div>{formik.errors.firstName}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="lastName">
                      <Form.Label>Last Name</Form.Label>{" "}
                      <span className="text-danger">*</span>
                      <Form.Control
                        type="text"
                        placeholder="Type here"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      <div className="text-primary">
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div>{formik.errors.lastName}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Phone</Form.Label>{" "}
                      <span className="text-danger">*</span>
                      <PhoneInput
                        preferredCountries={["us", "ca", "ma"]}
                        inputStyle={{
                          width: "100%",
                          padding: "1.5rem",
                          paddingLeft: "4rem",
                          borderColor: "#e4e6ef",
                        }}
                        country={"ca"}
                        name="phone"
                        value={formik.values.phone}
                        onChange={(value) =>
                          formik.setFieldValue("phone", value)
                        }
                      />
                      <div className="text-primary">
                        {formik.touched.phone && formik.errors.phone ? (
                          <div>{formik.errors.phone}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>{" "}
                      <span className="text-danger">*</span>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <div className="text-primary">
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Form>
                </>
              )}
            </Col>

            <Col xl="12" lg="12" md="12" xs="12" className="mb-10">
              {step === (OTCStatut === true ? (ChangeSteps === false ? 3 : 4) : 3) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      className="symbol symbol-30 symbol-circle  mr-5"
                      onClick={() => setStep(2)}
                    >
                      <span className="symbol-label" 
                      style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                          color:"white"
                      }}>2</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                      <h1 className="text-dark font-weight-semibold mb-0">
                        Delivery Address
                      </h1>
                    </div>
                  </div>
                </>
              )}
              {orderType && step === (OTCStatut === true ? (ChangeSteps === false ? 2 : 3) : 2) && (
                <>
                  <h1 className="font-weight-semibold mb-5">
                    Delivery Address
                  </h1>

                  <Form className="px-5 pb-10">
                    {/* <Form.Group className="mb-3" controlId="Unit">
                      <Form.Label>Unit</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type here"
                        name="unitNumber"
                        value={formik.values.unitNumber}
                        onChange={formik.handleChange}
                      />
                    </Form.Group> */}
                    <div className="row">
                      <div className="col-xl-5 col-md-6">
                        <Form.Group className="mb-3" controlId="AddressOne">
                          <Form.Label>Address 1</Form.Label>
                          <span className="text-danger">*</span>
                          <Form.Control
                            type="text"
                            placeholder="Type here"
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <div className="text-primary">
                          {formik.touched.address1 && formik.errors.address1 ? (
                            <div>{formik.errors.address1}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-5 col-md-6">
                        <Form.Group className="mb-3" controlId="AddressTwo">
                          <Form.Label>Address 2</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type here"
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xl-2 col-md-6">
                        <Form.Group className="mb-3" controlId="Buzz">
                          <Form.Label>Buzz code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type here"
                            name="buzzCode"
                            value={formik.values.buzzCode}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="Country">
                          <Form.Label>Country</Form.Label>
                          <span className="text-danger">*</span>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={countries}
                            value={countries.find(
                              (obj) => obj.value === formik.values.country
                            )}
                            onChange={(e) => {
                              formik.setFieldValue("state", "");
                              formik.setFieldValue("country", e.value);
                              getStates(e.value);
                            }}
                            name="Country"
                          />
                        </Form.Group>
                        <div className="text-primary">
                          {formik.touched.country && formik.errors.country ? (
                            <div>{formik.errors.country}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="City">
                          <Form.Label>City</Form.Label>
                          <span className="text-danger">*</span>
                          <Form.Control
                            type="text"
                            placeholder="Type here"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <div className="text-primary">
                          {formik.touched.city && formik.errors.city ? (
                            <div>{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="State">
                          <Form.Label>State/Province</Form.Label>
                          <span className="text-danger">*</span>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            options={states}
                            value={
                              formik.values.state !== ""
                                ? states.find(
                                  (obj) => obj.value === formik.values.state
                                )
                                : null
                            }
                            onChange={(e) =>
                              formik.setFieldValue("state", e.value)
                            }
                            name="State"
                          />
                        </Form.Group>
                        <div className="text-primary">
                          {formik.touched.state && formik.errors.state ? (
                            <div>{formik.errors.state}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="ZipCode">
                          <Form.Label>Postal Code</Form.Label>
                          <span className="text-danger">*</span>
                          <Form.Control
                            type="text"
                            placeholder="Type here"
                            name="zipCode"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <div className="text-primary">
                          {formik.touched.zipCode && formik.errors.zipCode ? (
                            <div>{formik.errors.zipCode}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Col>

            <Col xl="12" lg="12" md="12" xs="12" className="mb-10">
              {step === (OTCStatut === true ? (ChangeSteps === false ? 4 : 5) : 4) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      className="symbol symbol-30 symbol-circle  mr-5"
                    // onClick={() => setStep()}
                    >
                      <span className="symbol-label" 
                      style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                          color:"white"
                      }}>3</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                      <h1 className="text-dark font-weight-semibold mb-0">
                        RX
                      </h1>
                    </div>
                  </div>
                </>
              )}
              {orderType && step === (OTCStatut === true ? (ChangeSteps === false ? 3 : 4) : 3) && (
                <>
                  <h1 className="font-weight-semibold mb-5">RX</h1>
                  {orderType !== "newPrescription" && (
                    <Form.Group className="mb-3" controlId="rxNumber">
                      <Form.Label>Rx #</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type here"
                        name="rxNumber"
                        value={formik.values.rxNumber}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  )}
                  <div className="row my-5">
                    <div className="col-md-6">
                      <Form.Label>Prescription</Form.Label>
                      <Previews
                        files={files}
                        setFiles={setFiles}
                        onDelete={onDelete}
                        color={color}
                        OTCStatut={OTCStatut}
                        ChangeSteps={ChangeSteps}
                        isLoadingDs={isLoadingDs}
                      />
                    </div>
                    <div className="col-md-6">
                      <Form.Label>Insurance Coverage</Form.Label>
                      <Previews
                        files={filesInsurence}
                        setFiles={setFilesInsurence}
                        onDelete={onDeleteInsurence}
                        color={color}
                        OTCStatut={OTCStatut}
                        ChangeSteps={ChangeSteps}
                        isLoadingDs={isLoadingDs}
                      />
                    </div>
                  </div>
                </>
              )}
            </Col>
            <Col xl="12" lg="12" md="12" xs="12">
              {(step === (ChangeSteps === false ? 5 : 1) && categories === true && OTCStatut === true) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    {ChangeSteps === true ? "" : <div
                      className="symbol symbol-30 symbol-circle  mr-5"
                    // onClick={() => setStep()}
                    >{orderType === "NoThanks" && ChangeSteps === false?<span className="symbol-label" style={{
                      backgroundColor: isLoadingDs === false ?
                        (OTCStatut === true ?
                          (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                          : "#EC520B")
                        : "",
                        color:"white"
                    }}>2</span>:<span className="symbol-label" style={{
                      backgroundColor: isLoadingDs === false ?
                        (OTCStatut === true ?
                          (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                          : "#EC520B")
                        : "",
                        color:"white"
                    }}>{ChangeSteps === false ? 4 : 2}</span>}
                      
                    </div>}

                    <div className="d-flex flex-column font-weight-bold">
                      <h1 className="text-dark font-weight-semibold mb-0">
                        Our top Storefront categories
                      </h1>
                    </div>
                  </div>
                  {isLoadingCat && (
                    <div className="col-md-12 mb-5 pb-5">
                      <div className="card-xl-stretch me-md-6">
                        <div className="m-0">
                          <div className="fs-6 fw-bold d-flex justify-content-center">
                            <h1 className="text-muted mx-3 mt-17">
                              Loading...
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-center ">
                    <ul
                      className="nav nav-pills d-flex nav-pills-custom gap-3 mt-10"
                      role="tablist"
                    >
                      {/*begin::Items*/}
                      {DataCategories?.map((category) => {
                        return (
                          <li className="col-xl-4 col-md-6 col-lg-12 mb-5 scale-hover cursor-pointer  " onClick={() => {
                            setcategories(false)
                            setcategoryId(category?.id)
                            getProducts(category?.id, search, 1)
                            setcategoriesTyp(category?.name)
                            setpageNumber(1)
                          }}>
                            <div className={`d-flex justify-content-between align-items-center   rounded w-100 h-100px`}
                              style={{
                                borderColor: isLoadingDs === false ?
                                  (OTCStatut === true ?
                                    (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                    : "#EC520B")
                                  : "",
                                border: `1px solid ${isLoadingDs === false ?
                                  (OTCStatut === true ?
                                    (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                    : "#EC520B")
                                  : ""}`, // Set border style with 1px solid and $color
                                color: isLoadingDs === false ?
                                  (OTCStatut === true ?
                                    (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                    : "#EC520B")
                                  : "",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={category?.uriImage}
                                  className="w-60px h-60px mb-2 ml-10 mr-5"
                                  alt=""
                                />
                                <span className="text-gray-800 mr-5 font-weight-bold fs-2 d-block">
                                  {category?.name}
                                </span>
                              </div>
                              <i className="ki ki-bold-wide-arrow-next icon-lg mr-5" />
                            </div>
                          </li>
                        )
                      })}
                      {/*end::Items*/}
                    </ul>
                  </div>
                </>
              )}
              {(step === (ChangeSteps === false ? 5 : 1) && categories === false && OTCStatut === true) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      className="symbol symbol-30 symbol-circle symbol-primary mr-5 d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        setDataProducs([])
                        setcategories(true)
                      }}
                    >
                      <i className="ki ki-bold-wide-arrow-back icon-lg text-info mr-2" />
                      <h6 className="text-info mt-1">Back</h6>
                    </div>
                    <div className="d-flex font-weight-bold align-items-center">
                      <h1 className="text-dark font-weight-semibold mr-3">
                        Storefront products
                      </h1>
                    </div>
                  </div>
                  <div className="d-flex mb-5">
                    <div className=" col-xl-4 col-md-6 ">
                      <Form.Label>Search</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        value={search}
                        onChange={(e) => {
                          setDataProducs([])
                          setpageNumber(1)
                          setSearch(e.target.value)
                          getProducts(categoryId, e.target.value, 1)
                        }}
                      />
                    </div>
                    <div className=" col-xl-3 col-md-6 ">
                      <Form.Label></Form.Label>
                      <Select
                        className="basic-single mt-2"
                        classNamePrefix="Filter"
                        placeholder="Filter"
                        isSearchable={true}
                        options={DataFilter}
                        onChange={(e) => {
                          setDataProducs([])
                          setpageNumber(1)
                          setcategoriesTyp(e.label)
                          setcategoryId(e.value)
                          getProducts(e.value, search, 1)
                        }}
                        name="State"
                      />
                    </div>
                  </div>
                  <span className="rounded-pill  p-1 ml-5 col-sm-12 col-md-6 col-lg-4 mt-5 text-center mt-5" style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                      }}>
                    <span className="text-white p-3 mb-1">{categoriesTyp}</span>
                  </span>
                  {isLoading && (

                    <div className="col-md-12 mb-5 pb-5">
                      <div className="card-xl-stretch me-md-6">
                        <div className="m-0">
                          <div className="fs-6 fw-bold d-flex justify-content-center">
                            <h1 className="text-muted mx-3 mt-17">
                              Loading...
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {DataProducts?.length === 0 && isLoading === false ?
                    <div className="text-center">
                      <div>
                        <h3

                        > <img
                            src={toAbsoluteUrl("/media/svg/icons/General/Search-amico.svg")}
                            className="rounded w-300px h-300px cursor-pointer"
                            alt=""
                          />
                        </h3>
                        <h2 className="text-muted">No result found</h2>
                      </div>

                    </div>
                    : ""}
                  <ul
                    className="nav mt-5"
                  >

                    {DataProducts?.map((pro) => {
                      const isInOrder = orders.some((order) => order.id === pro.id);
                      const maxLength = 25;
                      const mainImage = pro?.images?.find(image => image?.isMain);
                      console.log(mainImage, pro)
                      const truncatedName = pro?.name && pro?.name?.length > maxLength
                        ? pro?.name.slice(0, maxLength) + '...'
                        : pro?.name;

                      return (
                        <li className="w-50 col-sm-6 col-md-4 col-lg-3 mb-5">

                          <div className="p-5 text-center h-100 border border-secondary rounded shadow-sm ">
                            <img
                              src={toAbsoluteUrl(mainImage?.uriImage ? mainImage?.uriImage : '/media/icons/no-image.png')}
                              className="rounded mb-4 h-100px h-xxl-150px cursor-pointer"
                              style={{
                                maxWidth: '100%', // Set maximum width to 100% of the parent div
                                height: 'auto',   // Maintain aspect ratio
                              }}
                              alt=""
                              onClick={() => {
                                getProductDetails(pro?.id);
                                handleShowDetail();
                              }}
                            />
                            <div>
                              <div className="mb-2 ">
                                <div className="d-flex justify-content-center mb-5">
                                  <span className="text-secondary text-end font-weight-bolder  fs-1  ">
                                    {truncatedName}
                                  </span>
                                </div>
                                {categoriesTyp === "All" && pro?.categories?.length > 0 ? <div className="rounded-pill  p-1   mt-2 text-center " style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                      }}>
                                  <span className="text-white p-2 mb-1">{pro?.categories[0]?.name}</span> <span>{pro?.categories?.length > 1 ? <OverlayTrigger
                                    key={"right"}
                                    placement={"right"}
                                    overlay={
                                      <Tooltip id={`tooltip-${"placement"}`}>
                                        {"Open product details to see all categories of this product"}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="svg-icon menu-icon svg-icon-white"
                                    >
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Code/Info-circle.svg"
                                        )}
                                      />
                                    </span>
                                  </OverlayTrigger> : ""}</span>
                                </div> : ""}

                              </div>
                              <div className="d-flex justify-content-center align-items-center">
                                <h5 className=" text-end font-weight-bolder  fs-1  mr-3" style={{
                                color: isLoadingDs === false ?
                                  (OTCStatut === true ?
                                    (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                    : "#EC520B")
                                  : "",
                              }}>${pro?.price?.toFixed(2)}</h5>
                                <div onClick={() => {
                                  if (isInOrder) {
                                    // Remove the product from the order based on its ID
                                    const updatedOrders = orders.filter((product) => product.id !== pro.id);
                                    setorders(updatedOrders);
                                  } else {
                                    // Add the product to the order
                                    const mainImage = pro?.images.find(image => image.isMain === true);
                                    console.log(mainImage, orders)
                                    console.log({ id: pro?.id, price: pro?.price, quantity: 1, image: mainImage },)
                                    setorders((prevOrders) => [...prevOrders, { id: pro?.id, price: pro?.price, quantity: 1, image: mainImage, name: pro?.name }]);
                                  }
                                }}>
                                  {isInOrder ? (
                                    <OverlayTrigger
                                      key={"top"}
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"placement"}`}>
                                          Remove from cart
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src="/media/icons/remove-cart.png"
                                        className="rounded mb-4 w-25px h-25px cursor-pointer scale-hover"
                                        alt=""
                                      />
                                    </OverlayTrigger>

                                  ) : (
                                    <OverlayTrigger
                                      key={"top"}
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"placement"}`}>
                                          Add to cart
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src="/media/icons/add-to-cart.png"
                                        className="rounded mb-4 w-25px h-25px cursor-pointer scale-hover"
                                        alt=""
                                      />
                                    </OverlayTrigger>

                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })}

                  </ul>
                  <div className="d-flex justify-content-end cursor-pointer mt-5">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className={`page-item ${pagination.isFirstPage ? 'disabled' : ''}`}>
                          <span className="page-link" onClick={() => {
                            setpageNumber(pagination.pageNumber - 1)
                            handlePageChange(pagination.pageNumber - 1)
                          }} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>

                        {/* Display page numbers */}
                        {Array.from({ length: pagination?.pageCount }, (_, index) => (
                          <li key={index} className={` `} 
                          style={{
                            backgroundColor: pagination.pageNumber === index + 1 ?
                              (OTCStatut === true ?
                                (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                : "#EC520B")
                              : "",
                            color: "white",
                          }}>
                            <span className="page-link" 
                            style={{
                              backgroundColor: pagination.pageNumber === index + 1 ?
                                (OTCStatut === true ?
                                  (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                  : "#EC520B")
                                : "",
                              color:  pagination.pageNumber === index + 1 ?
                              "white": 
                              (OTCStatut === true ?
                                (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                : "#EC520B")
                              ,
                            }}
                            onClick={() => {
                              if (index + 1 !== pageNumber) {
                                setpageNumber(index + 1)
                                handlePageChange(index + 1)
                              }
                            }}>
                              {index + 1}
                            </span>
                          </li>
                        ))}

                        <li className={`page-item ${pagination.isLastPage ? 'disabled' : ''}`}>
                          <span className="page-link"
                           style={{
                            color: "black",
                          }}
                          onClick={() => {
                            console.log(pagination.pageNumber, pagination)
                            setpageNumber(pagination.pageNumber + 1)
                            handlePageChange(pagination.pageNumber + 1)
                          }} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              )}
            </Col>
            <Col xl="12" lg="12" md="12" xs="12" className="mb-10">
              {step === (OTCStatut === true ? 7 : 6) && (
                <>
                  <div className="d-flex align-items-center mb-5">
                    <div
                      className="symbol symbol-30 symbol-circle  mr-5"
                    // onClick={() => setStep()}
                    >
                      <span className="symbol-label"style={{
                        backgroundColor: isLoadingDs === false ?
                          (OTCStatut === true ?
                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                            : "#EC520B")
                          : "",
                          color:"white"
                      }}>5</span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                      <h1 className="text-dark font-weight-semibold mb-0">
                        Additional information
                      </h1>
                    </div>
                  </div>
                </>
              )}
              {orderType && step === (OTCStatut === true ? (ChangeSteps === false ? 4 : 5) : 4) && (
                <>
                  <h1 className="font-weight-semibold mb-5">
                    Additional information
                  </h1>

                  <div className="form-group row align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                      <label>
                        Delivery date <span className="text-primary">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        name="deliveryAppointmentDate"
                        className="form-control"
                        placeholder="Delivery date"
                        value={moment(
                          formik.values.deliveryAppointmentDate
                        ).format("YYYY-MM-DD[T]HH:mm")}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "deliveryAppointmentDate",
                            e.target.value
                          );
                        }}
                      />
                      <div className="text-primary">
                        {formik.touched.deliveryAppointmentDate &&
                          formik.errors.deliveryAppointmentDate ? (
                          <div>{formik.errors.deliveryAppointmentDate}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-12 m-0 p-0 text-center">
                      <label> &nbsp;</label>
                      <h6 className="p-0 m-0"> To </h6>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                      <label> &nbsp;</label>
                      <input
                        type="time"
                        name="deliveryAppointmentToDate"
                        className="form-control"
                        placeholder="To"
                        value={moment(
                          formik.values.deliveryAppointmentToDate
                        ).format("HH:mm")}
                        onChange={(e) => {
                          const date = new Date();
                          const year = date.getFullYear();
                          const month = date.getMonth() + 1;
                          const monthString =
                            month < 10 ? `0${month}` : month;
                          const day = date.getDate();
                          const dayString = day < 10 ? `0${day}` : day;
                          const time = e.target.value;
                          const dateTime = `${year}-${monthString}-${dayString}T${time}`;
                          formik.setFieldValue(
                            "deliveryAppointmentToDate",
                            dateTime
                          );
                        }}
                      />
                      <div className="text-primary">
                        {formik.touched.deliveryAppointmentToDate &&
                          formik.errors.deliveryAppointmentToDate ? (
                          <div>
                            {formik.errors.deliveryAppointmentToDate}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Authorized person </label>
                      <TagsInput
                        value={authorizedPerson}
                        onChange={setAuthorizedPerson}
                        name="AuthorizedPerson"
                        placeHolder="Authorized person"
                      />
                    </div>
                  </div> */}
                  <TagInput
                    tags={tags}
                    setTags={setTags}
                    inputRef={inputRef}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                  />

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        name="notes"
                        rows="3"
                        placeholder="Notes"
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </Col>

            <Col xl="12" lg="12" md="12" xs="12">
              {step === (OTCStatut === true ? 6 : 5) && (
                <>
                  <h1 className="font-weight-semibold mb-5">
                    DELIVERY CONFIRMATION
                  </h1>
                  <div className="mb-5 pl-5">
                    <h3 className="font-weight-semibold mb-5">Order Details</h3>
                    <div className="d-flex flex-column flex-root mb-2">
                      <span className="font-weight-bolder mb-2">DATE</span>
                      <span className="opacity-70">
                        {moment(new Date()).format("DD/MM/YYYY")}
                        <OverlayTrigger
                          key={"right"}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-${"placement"}`}>
                              {getFromatdate()}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            className="svg-icon menu-icon svg-icon-info"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Info-circle.svg"
                              )}
                            />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                    {/* <div className="d-flex flex-column flex-root mb-2">
                      <span className="font-weight-bolder mb-2">Order NO.</span>
                      <span className="opacity-70">GS 000014</span>
                    </div> */}
                  </div>
                  <div className="mb-5 pl-5">
                    <h3 className="font-weight-semibold mb-5">
                      Delivery Address
                    </h3>
                    <div className="d-flex flex-column flex-root mb-2">
                      <span className="opacity-70">
                        {formik.values.address1}
                        {formik.values?.address2 && ","}{" "}
                        {formik.values?.address2 && formik.values?.address2}
                        {", "}
                        {formik.values?.city}, {formik.values?.state}{" "}
                        {formik.values?.zipCode}, {formik.values?.country}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12 mb-5">
                    <div className="flex-column" style={{ height: "350px" }}>
                   
                      {!!charges?.pharmacyLng  === true && (
                        <GoogleMap
                          PlannedPolyline={[
                            {
                              Name: "",
                              City: charges?.pharmacyCity,
                              State: charges?.pharmacyState,
                              Type: "Shipping",
                              Latitude: charges?.pharmacyLat,
                              Longitude: charges?.pharmacyLng,
                            },
                            {
                              Name: "",
                              City: charges?.patientCity,
                              State: charges?.patientState,
                              Type: "Receiving",
                              Latitude: charges?.patientLat,
                              Longitude: charges?.patientLng,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>

                  {OTCStatut === true ?
                    <div className="card card-flush bg-body mb-5" >
                      {/*begin::Header*/}
                      <div className=" d-flex justify-content-between align-items-center p-10">
                        <h3 className=" fw-bold text-gray-800 ">Current Order</h3>
                        {/*begin::Toolbar*/}
                        {/* <div className="card-toolbar" onClick={() => {
                          setorders([])
                        }}>
                          <span className="btn btn-light-primary fs-4 fw-bold py-4">
                            Clear All
                          </span>
                        </div> */}
                        {/*end::Toolbar*/}
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body pt-0 d-flex">
                        {/*begin::Table container*/}
                        <div className=" table-responsive ">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4 my-0">

                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>

                              {orders?.map((order, index) => {
                                console.log(order)
                                return (
                                  <tr data-kt-pos-element="item" data-kt-pos-item-price={33}>

                                    <td className="pe-0">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={toAbsoluteUrl(order.image?.uriImage ? order.image?.uriImage : '/media/icons/no-image.png')}
                                          className=" h-50px rounded-3 me-3"
                                          alt=""
                                        />
                                        <span className="fw-bold text-gray-800 cursor-pointer  fs-6 ml-3" style={{
                                  color: isLoadingDs === false ?
                                    (OTCStatut === true ?
                                      (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                      : "#EC520B")
                                    : "",
                                }}>
                                          {order?.name}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="pe-0 w-80px">
                                      {/*begin::Dialer*/}
                                      <div
                                        className="d-flex align-items-center"
                                      >
                                        {/*begin::Decrease control*/}
                                        <button
                                          type="button"
                                          className="btn btn-icon btn-sm btn-light btn-icon-gray-500"
                                          onClick={() => {
                                            setorders((prevOrders) => {
                                              const updatedOrders = [...prevOrders];
                                              updatedOrders[index].quantity = Math.max(
                                                1,
                                                updatedOrders[index].quantity - 1
                                              );
                                              return updatedOrders;
                                            });
                                          }}
                                        >
                                          <i class="ki ki-minus icon-md"></i>{" "}
                                        </button>
                                        {/*end::Decrease control*/}
                                        {/*begin::Input control*/}
                                        <input
                                          type="text"
                                          className="form-control border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-30px"
                                          data-kt-dialer-control="input"
                                          placeholder="Amount"
                                          name="manageBudget"
                                          value={order?.quantity}
                                          readOnly=""
                                        />
                                        {/*end::Input control*/}
                                        {/*begin::Increase control*/}
                                        <button
                                          type="button"
                                          className="btn btn-icon btn-sm btn-light btn-icon-gray-500"
                                          onClick={() => {
                                            console.log(formik.values.amount)
                                            setorders((prevOrders) => {
                                              const updatedOrders = [...prevOrders];
                                              updatedOrders[index].quantity += 1;
                                              return updatedOrders;
                                            });
                                          }}
                                        >
                                          <i class="ki ki-plus icon-md"></i>{" "}
                                        </button>
                                        {/*end::Increase control*/}
                                      </div>
                                      {/*end::Dialer*/}
                                    </td>
                                    <td className="text-center w-20px">
                                      <div
                                        className="mt-3 font-weight-boldest "
                                        style={{
                                          color: isLoadingDs === false ?
                                            (OTCStatut === true ?
                                              (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                              : "#EC520B")
                                            : "",
                                        }}
                                      >
                                        ${(order?.price * order?.quantity).toFixed(2)}
                                      </div>

                                    </td>
                                    <td className="text-center w-40px">

                                      <img
                                        onClick={() => {
                                          // Remove the order with the specified id
                                          const updatedOrders = orders.filter((o) => o.id !== order.id);
                                          setorders(updatedOrders);
                                        }}
                                        src="/media/icons/delete-button.png"
                                        className="rounded mb-4 w-25px h-25px cursor-pointer scale-hover mt-2"
                                        alt=""
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                              {orders.length === 0 ?
                                <div className="d-flex justify-content-center">
                                  <div className="text-center">

                                    <h2 className="text-muted">No orders available</h2>
                                  </div>

                                </div>
                                : ""}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                        {/*begin::Summary*/}
                        {/* <div className="col-md-6 d-flex  flex-stack bg-success rounded p-6 ">
                        <div className="fs-6 fw-bold text-white">
                          <span className="d-block lh-1 mb-2">Subtotal</span>
                          <span className="d-block mb-2">Discounts</span>
                          <span className="d-block mb-9">Tax(12%)</span>
                          <span className="d-block fs-2qx lh-1">Total</span>
                        </div>
                        <div className="fs-6 fw-bold text-white text-end">
                          <span className="d-block lh-1 mb-2" data-kt-pos-element="total">
                            $265.50
                          </span>
                          <span className="d-block mb-2" data-kt-pos-element="discount">
                            -$8.00
                          </span>
                          <span className="d-block mb-9" data-kt-pos-element="tax">
                            $11.20
                          </span>
                          <span
                            className="d-block fs-2qx lh-1"
                            data-kt-pos-element="grant-total"
                          >
                            $258.46
                          </span>
                        </div>
                      </div> */}

                      </div>
                    </div>
                    : ""}
                  <div className="mb-5 pl-5">
                    <h3 className="font-weight-semibold">
                      Delivery Fee Breakdown
                    </h3>
                    <div className="flex-row-auto w-xl-450px">

                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="font-weight-boldest">
                            <td className="pl-0 pt-5">
                              Distance Charge (
                              {Loading ? (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="sm"
                                />
                              ) : (
                                charges?.distance
                              )}{" "}
                              km):
                            </td>
                            <td className="text-right pt-5">
                              {Loading ? (
                                <Spinner animation="border" variant="primary" />
                              ) : (
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(charges?.charge)
                              )}
                            </td>
                          </tr>
                          {OTCStatut === true ?
                            <tr className="font-weight-boldest">
                              <td className="pl-0 pt-5">
                                Storefront product Total:
                              </td>
                              <td className="text-right pt-5">
                                ${totalOTC}
                              </td>
                            </tr>
                            : ""}
                          <tr className="font-weight-boldest border-bottom-0">
                            <td className="border-top-0 pl-0 py-4">
                              HST - Canada ({parseInt(charges?.taxPercentage)}%
                              on{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(charges?.charge + totalOTC)}
                              ):
                            </td>
                            <td className="border-top-0 text-right py-4">
                              {Loading ? (
                                <Spinner animation="border" variant="primary" />
                              ) : (
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(charges?.hstTax + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0))
                              )}
                            </td>
                          </tr>
                          <tr className="font-weight-boldest border-bottom-0">
                            <td className="border-top-0 pl-0 py-4">
                              <div className="checkbox-inline ">
                                <label className="checkbox checkbox-md">
                                  <input
                                    className=""
                                    type="checkbox"
                                    defaultValue
                                    name="tip"
                                    value={formik.values.tip}
                                    checked={formik.values.tip}
                                    onChange={() => {
                                      formik.setFieldValue(
                                        "tip",
                                        !formik.values.tip
                                      );
                                      setTipPerc(0);
                                      setTip(null);
                                    }}
                                  />
                                  <span></span>
                                  Tip
                                </label>
                              </div>
                            </td>
                            <td className="border-top-0 text-right py-4">
                              {formik.values.tip && (
                                <div className="radio-inline">
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      name="radios2"
                                      value="15"
                                      checked={tipPerc === 15}
                                      onChange={() => {
                                        setTipPerc(15);
                                        setTip(null);
                                      }}
                                    />
                                    <span></span>
                                    15%
                                  </label>
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      name="radios2"
                                      value="18"
                                      checked={tipPerc === 18}
                                      onChange={() => {
                                        setTipPerc(18);
                                        setTip(null);
                                      }}
                                    />
                                    <span></span>
                                    18%
                                  </label>
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      name="radios2"
                                      value="20"
                                      checked={tipPerc === 20}
                                      onChange={() => {
                                        setTipPerc(20);
                                        setTip(null);
                                      }}
                                    />
                                    <span></span>
                                    20%
                                  </label>
                                  <label className="radio">Or</label>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="tipAmount"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Tip Amount ($)"
                                      name="tipAmount"
                                      value={tip || ""}
                                      onChange={(e) => {
                                        const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
                                        if (regex.test(e.target.value)) {
                                          setTip(e.target.value);
                                          setTipPerc(0);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                              )}
                            </td>
                          </tr>


                          <tr className="font-weight-boldest border-bottom-0">
                            <td className="border-top-0 pl-0 py-4">Total:</td>
                            <td className="border-top-0 text-right py-4">
                              {Loading ? (
                                <Spinner animation="border" variant="primary" />
                              ) : charges?.tipAmount &&
                                charges?.tipAmount !== 0 ? (
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(charges?.tipAmount)
                              ) : (
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(charges?.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0))

                              )}
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </Col>

            {step === (OTCStatut === true ? 7 : 6) && (
              <Col xl="12" lg="12" md="12" xs="12" className="mb-20">
                <h1 className="mb-5 font-weight-semibold text-dark">
                  Enter your Payment Details
                </h1>
                <div className="row">
                  <div className="col-12">
                    {/*begin::Input*/}
                    <div className="form-group fv-plugins-icon-container">
                      <label>Name on Card</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        className="form-control  form-control-lg"
                        name="cardName"
                        placeholder="Name on Card"
                        value={formik.values.cardName}
                        onChange={formik.handleChange}
                      />
                      <div className="text-primary">
                        {formik.errors.cardName ? (
                          <div>{formik.errors.cardName}</div>
                        ) : null}
                      </div>
                      <span className="form-text text-muted">
                        Please enter your Name on Card.
                      </span>
                      <div className="fv-plugins-message-container" />
                    </div>
                    {/*end::Input*/}
                  </div>
                  <div className="col-12">
                    <Payment handleSubmit={handleCreateOrder} formik={formik} />
                  </div>
                  <div className="col-12 mt-7">
                    <div className="checkbox-inline mt-7">
                      <label className="checkbox checkbox-lg font-weight-bold">
                        <input
                          type="checkbox"
                          defaultValue
                          name="termsAndConditions"
                          value={formik.values.termsAndConditions}
                          checked={formik.values.termsAndConditions}
                          onChange={() => {
                            formik.setFieldValue(
                              "termsAndConditions",
                              !formik.values.termsAndConditions
                            );
                          }}
                        />
                        <span></span>
                      </label>
                      <h4
                        onClick={() => {
                          setShowModal(true);
                        }}
                        className="cursor-pointer"
                      >
                        Terms & Conditions<span className="text-danger">*</span>
                      </h4>
                    </div>
                    <div className="text-primary">
                      {formik.errors.termsAndConditions ? (
                        <div>{formik.errors.termsAndConditions}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {step === (OTCStatut === true ? 8 : 7) && (
              <>
                <div className="flex-row-fluid position-relative">
                  {/*begin::Background*/}
                  <div className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                    <i className="fa-thin fa-truck"></i>
                  </div>
                  {/*end::Background*/}
                  {/*begin::Card header*/}
                  <div className="px-5 mx-5 mt-5">
                    <div className="card-title">
                      <h1>Order Details</h1>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body pt-0">
                    <div className="mb-1">
                      <span className="font-weight-semibold h6 text-muted pr-3">
                        Order Number:
                      </span>
                      <span className="font-weight-semibold h6">
                        #{orderNumber}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="font-weight-semibold h6 text-muted pr-3">
                        Amount:
                      </span>
                      <span className="font-weight-semibold h6">
                        {charges?.tipAmount && charges?.tipAmount !== 0
                          ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(charges?.tipAmount)
                          : new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(charges?.totalWithTax + totalOTC + (totalOTC !== 0 ? (totalOTC * parseFloat(charges?.taxPercentage) / 100) : 0))}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="font-weight-semibold h6 text-muted pr-3">
                        Customer:
                      </span>
                      <span className="font-weight-semibold h6">
                        {formik.values.firstName} {formik.values.lastName}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="font-weight-semibold h6 text-muted pr-3">
                        Email:
                      </span>
                      <span
                        className="font-weight-semibold h6 cursor-pointer"
                        onClick={() => {
                          window.location.href = `mailto:${formik.values.email}`;
                        }}
                      >
                        {formik.values.email}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="font-weight-semibold h6 text-muted pr-3">
                        Phone:
                      </span>
                      <span
                        className="font-weight-semibold h6 cursor-pointer"
                        onClick={() => {
                          window.location.href = `tel:${formik.values.phone}`;
                        }}
                      >
                        {formik.values.phone}
                      </span>
                    </div>
                  </div>
                  {/*end::Card body*/}
                </div>
                <div className="flex-row-fluid position-relative">
                  {/*begin::Background*/}
                  <div className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                    <i className="fa-thin fa-truck"></i>
                  </div>
                  {/*end::Background*/}
                  {/*begin::Card header*/}
                  <div className="px-5 mx-5 mt-5">
                    <div className="card-title">
                      <h2>Delivery Address</h2>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body pt-0">
                    <span className="font-weight-semibold h6">
                      {formik.values.address1} {formik.values.address2 && ", "}
                      {formik.values.address2 && formik.values.address2}, <br />
                      {formik.values.city}, {formik.values.state}{" "}
                      {formik.values.zipCode}, <br />
                      {formik.values.country}
                    </span>
                  </div>
                  {/*end::Card body*/}
                </div>
                {OTCStatut === true ?
                  <div className="flex-row-fluid position-relative">
                    {/*begin::Background*/}
                    <div className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                      <i className="fa-thin fa-truck"></i>
                    </div>
                    {/*end::Background*/}
                    {/*begin::Card header*/}
                    <div className="px-5 mx-5 mt-5">
                      <div className="card-title">
                        <h2>Storefront details</h2>
                      </div>
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="card-body">
                      <div className=" pt-0 d-flex">
                        {/*begin::Table container*/}
                        <div className=" table-responsive ">
                          {orders?.length === 0 ? "" :
                            <div className="d-flex mb-5">
                              <div className="pe-0 col-6 font-weight-bold">
                                Product
                              </div>

                              <div className="pe-0 col-2 w-80px font-weight-bold">
                                {/*begin::Dialer*/}
                                <div
                                  className="d-flex  justify-content-center "
                                >
                                  <span>Price</span>
                                </div>
                                {/*end::Dialer*/}
                              </div>
                              <div className="pe-0 col-2 w-80px font-weight-bold">
                                {/*begin::Dialer*/}
                                <div
                                  className="d-flex  justify-content-center "
                                >
                                  <span>Quantity</span>
                                </div>
                                {/*end::Dialer*/}
                              </div>
                              <div className="text-center col-2 w-20px font-weight-bold">
                                Total
                              </div>
                            </div>
                          }

                          {/*begin::Table*/}

                          {orders?.map((order, index) => {
                            console.log(order)
                            return (
                              <div className={`d-flex ${index + 1 === orders ? "" : "mb-5"} `}>
                                <div className="pe-0 col-6 font-weight-bold">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={toAbsoluteUrl(order.image?.uriImage ? order.image?.uriImage : '/media/icons/no-image.png')}
                                      className=" h-50px rounded-3 me-3"
                                      alt=""
                                    />
                                    <span className="fw-bold text-gray-800 cursor-pointer  fs-6 ml-3 cursor-pointer"
                                      onClick={() => {
                                        console.log(order)
                                        handleShowDetail()
                                        getProductDetails(order?.id)
                                      }}
                                      style={{
                                        color: isLoadingDs === false ?
                                          (OTCStatut === true ?
                                            (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                            : "#EC520B")
                                          : "",
                                      }}
                                    >
                                      {order?.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="pe-0 col-2 w-80px font-weight-bold">
                                  {/*begin::Dialer*/}
                                  <div
                                    className="d-flex align-items-center justify-content-center mt-3"
                                  >
                                    <span>${order?.price.toFixed(2)}</span>
                                  </div>
                                  {/*end::Dialer*/}
                                </div>
                                <div className="pe-0 col-2 w-80px font-weight-bold">
                                  {/*begin::Dialer*/}
                                  <div
                                    className="d-flex align-items-center justify-content-center mt-3"
                                  >
                                    <span>{order?.quantity}</span>
                                  </div>
                                  {/*end::Dialer*/}
                                </div>
                                <div className="text-center col-2 w-20px font-weight-bold">
                                  <div
                                    className="mt-3 font-weight-boldest "
                                  >
                                    ${(order?.price * order?.quantity).toFixed(2)}
                                  </div>
                                </div>
                              </div>

                            )
                          })}
                          {orders?.length === 0 ?
                            <div className="d-flex justify-content-center">
                              <div className="text-center">
                                <h2 className="text-muted">No products available</h2>
                              </div>

                            </div>
                            : ""}
                        </div>
                      </div>
                      {orders?.length === 0 ? "" :
                        <div className="mt-5">
                          <div className="separator separator-dashed col-12 mb-3 mt-9"></div>
                          <div className=" d-flex align-items-center font-weight-boldest  ">
                            <div className="separator separator-dashed mb-2" />
                            <div className="col-6 pe-0 ">
                              Storefront products total:
                            </div>
                            <div className="col-2 ">
                            </div>
                            <div className="col-2 ">
                            </div>
                            <div className="col-2 text-center  "
                            style={{
                              color: isLoadingDs === false ?
                                (OTCStatut === true ?
                                  (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                  : "#EC520B")
                                : "",
                            }}
                            >
                              ${totalOTC.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                    {/*end::Card body*/}
                  </div>
                  : ""}

              </>
            )}
          </Container>
          {step <= (OTCStatut === true ? 7 : 6) && (
            <div className="row">
              {step > 1 && (
                <div className="prev-button col-6">
                  <div className="prev-button">
                    <button
                      style={{ backgroundColor: isLoadingDs === false ? (OTCStatut === true ? (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B") : "#EC520B") : "" }}
                      disabled={isLoading}
                      className="next-button-fixed"
                      onClick={handlePrevStep}
                    >
                      PREVIOUS
                    </button>
                  </div>
                </div>
              )}
              <div className="next-button col-6">
                <div
                  style={{
                    textAlign: "center",
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    width: `${step > 1 ? "50%" : "100%"}`,
                  }}
                >
                  {step < totalSteps ? (
                    <button
                      style={{ backgroundColor: isLoadingDs === false ? (OTCStatut === true ? (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B") : "#EC520B") : "#F3F6F9" }}
                      className="next-button-fixed"
                      onClick={() => {
                        if (OTCStatut === false) {
                          formik.handleSubmit()
                        } else {
                          if (ChangeSteps === false) {
                            formik.handleSubmit()
                          } else {
                            if (step === 1) {
                              handleNextStep()
                            } else {
                              formik.handleSubmit()
                            }
                          }
                        }
                      }}
                      type="button"
                    >
                      NEXT
                    </button>
                  ) : (
                    <button
                      style={{ backgroundColor: isLoadingDs === false ? (OTCStatut === true ? (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B") : "#EC520B") : "" }}
                      disabled={isLoading}
                      className="next-button-fixed"
                      type="button"
                      onClick={formik.handleSubmit}
                    >
                      {isLoading ? "PLEASE WAIT..." : "SUBMIT"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {step > (OTCStatut === true ? 7 : 6) && (
            <div className="row">
              <div className="next-button col-6">
                <div
                  style={{
                    textAlign: "center",
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    width: "100%",
                  }}
                >
                  <button
                    style={{ backgroundColor: isLoadingDs === false ? (OTCStatut === true ? (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B") : "#EC520B") : "" }}
                    className="next-button-fixed"
                    onClick={resetForm}
                    type="button"
                  >
                    ADD ANOTHER ORDER
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        size="xl"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll" style={{ height: 550, overflow: "auto" }}>
            <div dangerouslySetInnerHTML={{ __html: termsInfo?.value }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => {
              formik.setFieldValue("termsAndConditions", true);
              setShowModal(false);
            }}
          >
            Agree
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDetail}
        onHide={handleCloseDetail}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> {(`  Product Details`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" h-100">
            {/*begin::Container*/}
            <div className="h-100 ">
              {!!productDetail === true ?
                <div className="d-flex flex-column flex-lg-row   align-items-center">
                  {/*begin::Image*/}
                  {productDetail !== null ?
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                      <div
                        className=" bgi-no-repeat bgi-size-cover rounded h-250px w-350px text-center"
                      >{productDetail?.images.length > 0 ?
                        <Slide style={{ position: 'relative', height: '300px', width: '400px' }}>
                          {productDetail?.images?.map((image, index) => {
                            return (
                              <img
                                key={index}
                                src={`${image?.uriImage}`}
                                style={{
                                  maxWidth: '100%', // Set maximum width to 100% of the parent div
                                }}
                                className="rounded mb-4  h-250px cursor-pointer"
                                alt=""
                              />
                            )
                          })}

                        </Slide>
                        : <img
                          src={`/media/icons/no-image.png`}
                          className="rounded mb-4 w-250px h-250px cursor-pointer"
                          alt=""
                        />}
                      </div>
                    </div>

                    : ""}

                  {/*end::Image*/}
                  {/*begin::Title*/}
                  <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className=" ml-3">
                      <div className=" py-5 d-flex align-items-center pt-7 pb-1 m-0">
                        <span
                          className=" font-weight-bolder text-dark-75 text-hover-primary font-size-h5  "
                        >
                          {productDetail?.name}
                        </span>
                        <span
                          className={`label label-${productDetail?.isActive ? "success" : "danger"
                            } label-pill label-inline ml-5`}
                        >
                          {productDetail?.isActive === true ? "Active" : "Inactive"}
                        </span>

                      </div>
                      <div className="font-weight-bold text-dark-50 font-size-sm  d-flex justify-content-start">
                        <div dangerouslySetInnerHTML={{ __html: productDetail?.description }} />
                      </div>
                      <h1 className=" text-end  fw-bold mb-7 fs-1" style={{
                                color: isLoadingDs === false ?
                                  (OTCStatut === true ?
                                    (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                    : "#EC520B")
                                  : "",
                              }}>${productDetail?.price?.toFixed(2)}</h1>

                      <div className="mb-10">
                        <label className="form-label d-block mb-3">Tags</label>
                        {productDetail?.tags?.map((tag) => {
                          return (
                            <>
                              {!!tag === true ? <span
                                className={`label label-muted label-pill label-inline mr-2`}
                                style={{
                                  backgroundColor: isLoadingDs === false ?
                                    (OTCStatut === true ?
                                      (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                      : "#EC520B")
                                    : "",
                                  color: "white",
                                }}
                              >
                                {tag}
                              </span> : ""}
                            </>
                          )
                        })}
                      </div>
                      <div className="mb-10">
                        <label className="form-label d-block mb-3">Categories</label>
                        {productDetail?.categories?.map((category) => {
                          return (
                            <>
                              {!!category === true ? <span
                                className={`label label-pill label-inline mr-2 mt-1`}
                                style={{
                                  backgroundColor: isLoadingDs === false ?
                                    (OTCStatut === true ?
                                      (ChangeSteps === true ? (!!color === true ? color : "#EC520B") : "#EC520B")
                                      : "#EC520B")
                                    : "",
                                  color: "white",
                                }}
                              >
                                {category?.name}
                              </span> : ""}
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                </div>
                : ""}
              {isLoadingDET && (

                <div className="col-md-12 mb-5 pb-5">
                  <div className="card-xl-stretch me-md-6">
                    <div className="m-0">
                      <div className="fs-6 fw-bold d-flex justify-content-center">
                        <h1 className="text-muted mx-3 mt-17">
                          Loading...
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-end"
            >
              <button
                type="button"
                onClick={handleCloseDetail}
                className="btn btn-light btn-elevate"
              >
                {("Cancel")}
              </button>
            </div>
            {/*end::Footer*/}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
