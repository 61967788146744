import MarkerClusterer from "@google/markerclusterer";
import GoogleMapReact from "google-map-react";
import React, { Component } from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

const exampleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#ABE8C7",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
];
export default class GoogleMapContainer extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
    script.async = true;
    document.body.appendChild(script);
  }
  setGoogleMapRef(map, maps) {
    //const history = useHistory();

    let _isDashboard = this.props.isDashboard;
    let _history = this.props.history;
    const infoWindow = new maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    this.googleMapRef = map;
    this.googleRef = maps;
    var min = 0.999999;
    var max = 1.000001;
    let locations = this.props.LoadsPositionList.filter(
      (a) => a.lat !== null
    ).map((a) => {
      return {
        lat: parseFloat(a.lat * (Math.random() * (max - min) + min)),
        lng: parseFloat(a.lng * (Math.random() * (max - min) + min)),
        city: a.city,
        state: a.state,
        id: a.id,
        code: a.code,
        name: a.name,
        address1: a.address1,
        zipCode: a.zipCode,
      };
    });
    let markers =
      locations &&
      locations.map((location) => {
        var tt = new this.googleRef.Marker({
          position: location, //public\media\icons
          icon: {
            url: toAbsoluteUrl("/media/icons/pharmacyMarker.png"),
            scaledSize: new this.googleRef.Size(40, 40), // Adjust the size here
          },
          map: map,
        });
        tt.addListener("click", () => {
          infoWindow.setContent(
            `<div class="polyButton" style="cursor: pointer;" data-id="${location.id
            }" style=padding-top:0.8rem>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Pharmcay:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${location.name
            }</div>
        </div>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Location:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${location.city !== null
              ? location.address1 +
              ", " +
              location.city +
              ", " +
              location.state +
              ", " +
              location.zipCode
              : ""
            }</div>
          
        </div>
          </div>`
          );
          infoWindow.open(map, tt);
        });
        maps.event.addListener(infoWindow, "domready", function () {
          let eee = document.getElementsByClassName("polyButton");
          for (
            var x = 0, sampleDivsLength = eee.length;
            x < sampleDivsLength;
            x++
          ) {
            //Add an event listener for each DOM element
            eee[x].addEventListener("click", function () {
              if (_isDashboard) {
                _history.push(`/rxdrop/${this.dataset.id}`);
              }
            });
          }
        });
        return tt;
      });
    var clusterStyles = [
      {
        textColor: "white",
        url: toAbsoluteUrl("/media/icons/directory.png"),
        height: 50,
        width: 50,
      },
      {
        textColor: "white",
        url: toAbsoluteUrl("/media/icons/D2.png"),
        height: 54,
        width: 54,
      },
      {
        textColor: "white",
        url: toAbsoluteUrl("/media/icons/directory.png"),
        height: 50,
        width: 50,
      },
    ];
    new MarkerClusterer(map, markers, {
      styles: clusterStyles,
      minimumClusterSize: 2,
    });

    // Check if the browser supports geolocation
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          console.log("🚀 ~ GoogleMapContainer ~ setGoogleMapRef ~ userLocation:", userLocation)
          map.setCenter(userLocation); // Center the map on the user's location
          this.setState({ userLocation });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }

  render() {
    const   MarkerItem = () => (
      <SVG src={toAbsoluteUrl("/media/icons/markerHere.svg")} />
    );
    return (
      <>
        {this.props.LoadsPositionList.length > 0 ? (
          <GoogleMapReact
            key={1}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              this.setGoogleMapRef(map, maps)
            }
            defaultCenter={{ lat: 43.65107, lng: -79.347015 }}
            defaultZoom={9}
            options={{ streetViewControl: true, styles: exampleMapStyles }}
          >
            <MarkerItem lat={this.state?.userLocation?.lat}
              lng={this.state?.userLocation?.lng} />
          </GoogleMapReact>
        ) : (
          <GoogleMapReact
            key={2}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{ lat: 43.65107, lng: -79.347015 }}
            defaultZoom={9}
            options={{ streetViewControl: true, styles: exampleMapStyles }}
          >
           <MarkerItem lat={this.state?.userLocation?.lat}
              lng={this.state?.userLocation?.lng} />

          </GoogleMapReact>
        )}
      </>
    );
  }
}
