import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function PharmacyLogo({ domain, clearbitApiKey, logo }) {
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://logo.clearbit.com/${domain}?size=100&key=${clearbitApiKey}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.url;
      })
      .then((url) => {
        setLogoUrl(url);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [domain, clearbitApiKey]);

  // Define a default icon URL
  const defaultIconUrl = toAbsoluteUrl("/media/bg/ph.png");

  return (
    <>
      {!logo ? (
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div class="symbol symbol-70 mr-3">
              <img
                src={logoUrl || defaultIconUrl}
                alt="Pharmacy Logo"
                  style={{ width: "100px", objectFit: "cover" }}
                className="me-4"
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div class="symbol symbol-70 mr-3">
              <img
                src={logo}
                alt="Pharmacy Logo"
                className="me-4"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default PharmacyLogo;
