import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LiveTracking } from "../../../_metronic/_partials/widgets/dashboard-chart/LiveTracking";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ModelStateMessage } from "../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import PharmacyLogo from "./PharmacyLogo";
import { CheckboxLargeWB } from "../../../_metronic/_partials/controls/forms/CheckboxLargeWB";
import { currencyFormat } from "../../../_metronic/_helpers/GlobalHelpers";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Schema = [
  Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    address1: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Postal code is required"),
  }),
];
const Pharmacies = () => {
  const [pharmacies, setPharmacies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [type, setType] = useState("List");
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [isLoading, setIsLoading] = useState(null);
  const [sumbmiting, setIsSubmiting] = useState(false);
  const [checkedNearby, setCheckedNearby] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    lat: "",
    lng: ""
  });
  const [pagination, setPagination] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [OrdersList, setOrdersList] = useState([]);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const GetPharmacies = () => {
    setIsLoading(true);
    fetch(
      `${BASE_URL}/RxDrop/Pharmacies?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=desc${checkedNearby ? `&sortField=distance&currentLat=${currentLocation.lat}&currentLng=${currentLocation.lng}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setPharmacies(data[1].pharmacies);
          setPagination(data[1].pagination);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getCountires = () => {
    fetch(`${BASE_URL}/Generic/Countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.countries.map((country) => {
            return { value: country.name, label: country.name };
          });
          setCountries(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStates = (code) => {
    fetch(`${BASE_URL}/Generic/States?name=${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.states.map((state) => {
            return { value: state.name, label: state.name };
          });
          setStates(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetPharmaciesMap = () => {
    fetch(`${BASE_URL}/RxDrop/MapViewPharmacies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setOrdersList(data[1]?.viewModel);
        } else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetPharmaciesMap();
    getCountires();
    getStates("Canada");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(userLocation);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (pagination) {
      let items = [];
      for (let i = 1; i <= pagination.pageCount; i++) {
        items.push(i);
        setPageNumbers(items);
      }
    }
  }, [pagination]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      GetPharmacies();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, pageNumber, pageSize, checkedNearby, currentLocation]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "Ontario",
      zipCode: "",
      country: "Canada",
      name: "",
    },
    validationSchema: Schema[0],
    onSubmit: (values) => {
      handleInvitePharmacy();
    },
  });

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  const handleInvitePharmacy = () => {
    setIsSubmiting(true);
    fetch(`${BASE_URL}/RxDrop/RequestPharmacy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formik.values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setIsSubmiting(false);
          formik.resetForm();
          Swal.fire({
            position: "center",
            title: "Success!",
            text: "Pharmacy invited successfully",
            showConfirmButton: true,
            timer: 5000,
          });
          handleClose();
        } else {
          setIsSubmiting(false);
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        setIsSubmiting(false);
        console.log(error);
      });
  };
  return (
    <>
      <div
        className="content  pt-0  d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        {/*begin::Entry*/}
        {/*begin::Hero*/}
        <div
          className="d-flex flex-row-fluid bgi-size-cover bgi-position-center"
          style={{
            backgroundImage: "linear-gradient(to top, #f6a985, #ec520b)",
            backgroundColor: "#f1d5c9",
            backgroundPosition: "center",
            backgroundSize: "cover",
            maxHeight: "400px",
          }}
        >
          <div className=" container ">
            {/*begin::Topbar*/}
            <div className="d-flex justify-content-center align-items-center border-bottom border-white py-7">
              <h3 className="h2 text-white mb-0 font-weight-bolder">RxDrop</h3>
              {/* <div className="d-flex">
                <span
                  onClick={() => window.open("https://rxdrop.co/terms.html")}
                  className="font-size-h6 font-weight-bold text-white cursor-pointer"
                >
                  Terms
                </span>
                <span
                  onClick={() =>
                    window.open("https://rxdrop.co/privacy-policy.html")
                  }
                  className="font-size-h6 font-weight-bold ml-8 text-white cursor-pointer"
                >
                  Privacy Policy
                </span>
              </div> */}
            </div>
            {/*end::Topbar*/}
            <div className="d-flex align-items-stretch text-center flex-column py-20">
              {/*begin::Heading*/}
              <h1 className="text-white font-weight-bolder mb-12">
              Find a Pharmacy Near You
              </h1>
              {/*end::Heading*/}
              {/*begin::Form*/}
              <form className="d-flex position-relative w-75 px-lg-40 m-auto">
                <div className="input-group">
                  {/*begin::Icon*/}
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white border-0 py-7 px-8">
                      <span className="svg-icon svg-icon-xl">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>{" "}
                    </span>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Input*/}
                  <input
                    type="text"
                    className="form-control h-auto border-0 py-7 px-1 font-size-h6"
                    placeholder="Search pharmacy..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {/* <button
                    onClick={handleShow}
                    type="button"
                    className="btn btn-primary rounded-0 ml-2"
                  >f
                    Invite Pharmacy
                  </button> */}
                  {/*end::Input*/}
                </div>
              </form>
              <div className="d-flex flex-wrap justify-content-center mt-5">
                <button
                  onClick={() => setType("List")}
                  className={`btn btn-light-primary mr-2 ${type === "List" &&
                    "active"}`}
                >
                  List View
                </button>
                <button
                  onClick={() => setType("Map")}
                  className={`btn btn-light-primary mr-2 ${type === "Map" &&
                    "active"}`}
                >
                  Map View
                </button>
              </div>
              {/*end::Form*/}
            </div>
          </div>
        </div>
        {/*end::Hero*/}
        {type === "List" && (
          <div className="container">
            <div className="row my-10">
              <div className="col-12 mb-10">
                <div className="d-flex justify-content-between">
                  <h1 className="font-weight-bold">List View</h1>
                  <CheckboxLargeWB
                    className="font-size-md"
                    text={"Nearby pharmacies"}
                    checked={checkedNearby}
                    onChange={(e) => {
                      setCheckedNearby(e.target.checked);
                    }}
                  />
                </div>
              </div>
              {!isLoading &&
                pharmacies &&
                pharmacies.map((pharmacy, index) => (
                  <div className="col-xl-4 col-lg-4 col-md-6 mb-5" key={index}>
                    {/*begin::Address*/}
                    <div className="card card-dashed h-xl-100 p-6">
                      <div className="d-inline">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
                          {/*begin::Symbol*/}
                          <PharmacyLogo
                            key={index}
                            domain={pharmacy.site}
                            clearbitApiKey={"sk_4bdd454077cd641e3246cc54267f5fe9"}
                            logo={pharmacy.logo}
                          />
                          {/*end::Symbol*/}
                          {/*begin::Text*/}
                          <div className="d-flex flex-column font-weight-bold">
                            <Link
                              to={`/rxdrop/${pharmacy.id}`}
                              className="text-dark text-hover-primary mb-1 font-size-lg"
                            >
                              {pharmacy.name}
                            </Link>
                            <span className="text-muted">
                              {pharmacy.address1}
                              {pharmacy.address2 && <br />}
                              {!!pharmacy.address2 ? pharmacy.address2 : <br />}
                              <br />
                              {pharmacy.city}, {pharmacy.state},{" "}
                              {pharmacy.zipCode}
                              <br />
                              {pharmacy.country}
                            </span>
                            {!!pharmacy?.distance && <div className="d-flex justify-content-end">
                              <span className="font-weight-bold text-danger">{currencyFormat(pharmacy?.distance)} KM</span>
                          </div>}
                          </div>
                          {/*end::Text*/}
                          {/* <div className="d-flex"> */}
                          {/* <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="metronic-features">
                                Call Pharmacy
                              </Tooltip>
                            }
                          >
                            <span
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={() =>
                                window.open(
                                  `tel:${pharmacy.contactPhoneNumber}`
                                )
                              }
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Call.svg"
                                  )}
                                />
                              </span>
                            </span>
                          </OverlayTrigger> */}

                          {/* </div> */}
                        </div>
                        <Link
                          to={`/rxdrop/${pharmacy.id}`}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm w-100 p-2 mt-4"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Shopping/Cart2.svg"
                              )}
                            />
                          </span>
                          <span className="pt-1">Schedule your delivery</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}

              {isLoading === true &&
                [1, 2, 3, 4, 5, 6].map((pharmacy, index) => (
                  <div className="col-xl-4 col-lg-4 col-md-6 mb-5" key={index}>
                    <div className="d-flex align-items-center">
                      <Skeleton count={1} height={150} width={400} />
                    </div>
                  </div>
                ))}
              {isLoading === false && pharmacies.length === 0 && (
                <div
                  style={{ height: "350px", width: "100%" }}
                  className="mb-5 d-flex flex-column align-items-center justify-content-center"
                >
                  <img
                    src={toAbsoluteUrl("/media/bg/Questions.svg")}
                    alt=""
                    className="me-4"
                    style={{ maxWidth: "400px" }}
                  />
                  <h2 className="font-weight-bolder mb-4">
                    Don't see your pharmacy? ?{" "}
                  </h2>
                  <div className="d-flex align-items-center">
                    <button
                      onClick={handleShow}
                      type="button"
                      className={`btn btn-light-primary mx-2 active`}
                    >
                      Invite them
                    </button>
                  </div>
                </div>
              )}
            </div>
            {!isLoading && pharmacies.length > 0 && (
              <div className="d-flex justify-content-end mt-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="d-flex flex-wrap py-2 mr-3">
                    {pageNumbers.map((page) => (
                      <button
                        disabled={isLoading}
                        key={page}
                        onClick={() => setPageNumber(page)}
                        className={`btn btn-icon btn-sm border-0 btn-hover-primary ${
                          page === pageNumber ? "active" : ""
                        } mr-2 my-1`}
                      >
                        {page}
                      </button>
                    ))}
                  </div>
                  <div className="d-flex align-items-center py-3">
                    <select
                      className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
                      style={{ width: 75 }}
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {type === "Map" && (
          <div className="container">
            <div className="row mt-10">
              <div className="col-12 mb-10">
                <h1 className="font-weight-bold">Map View</h1>
              </div>
              <div className="col-12">
                <LiveTracking
                  OrdersList={OrdersList}
                  className="gutter-b card-stretch"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Invite Pharmacy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form">
            <div className="card-body">
              <div className="row">
                <div className="col-6 ">
                  <div className="form-group">
                    <label>First Name:</label>{" "}
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="Enter first name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="form-group">
                    <label>Last Name:</label>{" "}
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Enter last name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Email address:</label>{" "}
                <span className="text-danger">*</span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <div className="text-primary">
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label>Phone:</label>
                <PhoneInput
                  preferredCountries={["us", "ca", "ma"]}
                  inputStyle={{
                    width: "100%",
                    padding: "1.5rem",
                    paddingLeft: "4rem",
                    borderColor: "#e4e6ef",
                  }}
                  country={"ca"}
                  name="phone"
                  value={formik.values.phone}
                  onChange={(value) => formik.setFieldValue("phone", value)}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Pharmacy Name</label>{" "}
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter pharmacy name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 1:</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      name="address1"
                      className="form-control"
                      placeholder="Enter address"
                      value={formik.values.address1}
                      onChange={formik.handleChange}
                    />
                    <div className="text-primary">
                      {formik.touched.address1 && formik.errors.address1 ? (
                        <div>{formik.errors.address1}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 2:</label>{" "}
                    <input
                      type="text"
                      name="address2"
                      className="form-control"
                      placeholder="Enter address"
                      value={formik.values.address2}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Country:</label>{" "}
                    <span className="text-danger">*</span>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={countries}
                      value={countries.find(
                        (obj) => obj.value === formik.values.country
                      )}
                      onChange={(e) => {
                        formik.setFieldValue("state", "");
                        formik.setFieldValue("country", e.value);
                        getStates(e.value);
                      }}
                      name="Country"
                    />
                    <div className="text-primary">
                      {formik.touched.country && formik.errors.country ? (
                        <div>{formik.errors.country}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>City:</label> <span className="text-danger">*</span>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Enter address"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                    />
                    <div className="text-primary">
                      {formik.touched.city && formik.errors.city ? (
                        <div>{formik.errors.city}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>State/Province:</label>{" "}
                    <span className="text-danger">*</span>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={states}
                      value={
                        formik.values.state !== ""
                          ? states.find(
                              (obj) => obj.value === formik.values.state
                            )
                          : null
                      }
                      onChange={(e) => formik.setFieldValue("state", e.value)}
                      name="State"
                    />
                    <div className="text-primary">
                      {formik.touched.state && formik.errors.state ? (
                        <div>{formik.errors.state}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Postal code:</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      name="zipCode"
                      className="form-control"
                      placeholder="Enter address"
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                    />
                    <div className="text-primary">
                      {formik.touched.zipCode && formik.errors.zipCode ? (
                        <div>{formik.errors.zipCode}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={sumbmiting}
            variant="primary"
            onClick={formik.handleSubmit}
          >
            {sumbmiting ? (
              <>
                Inviting...{" "}
                <span className="spinner-border spinner-border-sm ml-2"></span>
              </>
            ) : (
              "Invite"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Pharmacies;
