import React from "react";
import GoogleMapReact from "google-map-react";
import { toAbsoluteUrl } from "../../_helpers";

const exampleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ABE8C7",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#CFE2EB",
      },
    ],
  },
];
const DetailTrakingGeometryInfo = [];

export function GoogleMap({ PlannedPolyline }) {
  const renderMarkers = async (map, maps) => {
    var bounds = new maps.LatLngBounds();
    const infoWindow = new maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    var data2 = PlannedPolyline?.map((item) => {
      return {
        lat: Number(item?.Latitude),
        lng: Number(item?.Longitude),
        Location: item?.City + ", " + item?.State,
      };
    });
    const directionsService2 = new maps.DirectionsService();
    const directionsRenderer2 = new maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "#13215F",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      },
    });
    calculateAndDisplayRoute2(directionsService2, directionsRenderer2);

    function calculateAndDisplayRoute2(
      directionsService2,
      directionsRenderer2
    ) {
      window.gMap = map;
      data2.map((items, index) => {
        let _marker1 = new maps.Marker({
          position: items,
          map: map,
          icon: {
            url:
              index === 0
                ? toAbsoluteUrl("/media/icons/point1.svg")
                : index === data2.length - 1
                ? toAbsoluteUrl("/media/icons/point2.svg")
                : toAbsoluteUrl("/media/icons/Stop 1.png"),
          },
        });
        _marker1.addListener("click", () => {
          infoWindow.setContent(
            `<div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Location:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${items?.Location}</div>
        </div>`
          );
          infoWindow.open(map, _marker1);
        });
        bounds.extend(_marker1.position);

        return false;
      });
      for (var j = 0, parts = [], max = 22 - 1; j < data2.length; j = j + max) {
        parts.push(data2.slice(j, j + max + 1));
      }
      // map.fitBounds(bounds);
      for (var k = 0; k < parts.length; k++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          destination: data2[data2.length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        directionsService2.route(service_options, (response, status) => {
          if (status !== "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer2);
          directionsRenderer2.setMap(map);
          directionsRenderer2.setOptions({
            suppressMarkers: true,
            preserveViewport: false,
          });
          directionsRenderer2.setDirections(response);
        });
      }
    }
    directionsRenderer2.setMap(map);

    //actual
    var data1 = DetailTrakingGeometryInfo?.map((item) => {
      return {
        lat: Number(item?.Latitude),
        lng: Number(item?.Longitude),
        Location: item?.City + ", " + item?.State,
        EventName: item?.EventName,
        EventDate: item?.EventDate,
      };
    });
    const directionsService = new maps.DirectionsService();
    calculateAndDisplayRoute(directionsService);

    async function calculateAndDisplayRoute(directionsService) {
      window.gMap = map;
      data1.map((items, index) => {
        let _marker2 = new maps.Marker({
          position: items,
          map: map,
          icon: {
            url: toAbsoluteUrl("/media/icons/steps.png"),
          },
        });

        _marker2.addListener("click", () => {
          infoWindow.setContent(
            `<div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Location:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${items?.Location}</div>
          
        </div>
        <div class="" style=background-color:#F5F8FA;color:#7E8299;font-family:monospace;padding:0.5rem;margin-bottom:0.5rem;display:flex;border-radius:7px;>
          <div style=margin-right:0.5rem;>Event:</div>
          <div style=flex-grow:1;flex-shrink:1;min-width:0;overflow:hidden;font-weight:bold>${items?.EventName} - ${items?.EventDate}</div>
          
        </div>`
          );
          infoWindow.open(map, _marker2);
        });
        return false;
      });
      for (var j = 0, parts = [], max = 22 - 1; j < data1.length; j = j + max) {
        parts.push(data1.slice(j, j + max + 1));
      }
      for (var k = 0; k < parts.length; k++) {
        const directionsRenderer = new maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: "#6993ff",
            strokeOpacity: 3.0,
            strokeWeight: 4,
          },
        });
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          //destination: data1[data1.length - 1],
          destination: parts[k][parts[k].length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        await directionsService.route(service_options, (response, status) => {
          if (status !== "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer);
          directionsRenderer.setMap(map);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
          });
          directionsRenderer.setDirections(response);
          directionsRenderer.setMap(map);
        });
      }
    }
  };

  const keyUp = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  return (
    <>
      {DetailTrakingGeometryInfo?.length > 0 || PlannedPolyline?.length > 0 ? (
        <GoogleMapReact
          key={1}
          bootstrapURLKeys={{
            key: keyUp,
          }}
          defaultCenter={{
            lat: 37.923984,
            lng: -97.539071,
          }}
          options={{ styles: exampleMapStyles }}
          defaultZoom={4}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        />
      ) : (
        <GoogleMapReact
          key={2}
          bootstrapURLKeys={{
            key: keyUp,
          }}
          defaultCenter={{
            lat: 37.923984,
            lng: -97.539071,
          }}
          defaultZoom={4}
          options={{ styles: exampleMapStyles }}
        />
      )}
    </>
  );
}
