import React from "react";
import moment from "moment";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import i18next from "i18next";

export function getItemArrayColors(statusName) {
  switch (statusName) {
    case "Draft":
      return "#C0C0C0";
    case "Open":
      return "#4caf50";
    case "Booked":
      return "#8950FC";
    case "Out for pickup":
      return "#32C5D2";
    case "Ready for pickup":
      return "#ff7c43";
    case "In transit":
      return "#6993ff";
    case "Assigned":
      return "#FFA800";
    case "Delivered":
      return "#009688";
    case "Closed":
      return "#991f17";
    case "RTS":
      return "#6D73D9";
    case "Delayed":
      return "#F64E60";
    default:
      return "";
  }
}

export function getFromatdate() {
  if (
    localStorage.getItem("dateFormat") === null ||
    localStorage.getItem("dateFormat") === undefined ||
    localStorage.getItem("dateFormat") === ""
  ) {
    return "DD/MM/YYYY";
  } else {
    return localStorage.getItem("dateFormat");
  }
}

function numberFormat(no) {
  var str = no + "";
  var ar = [];
  var i = str.length - 1;
  while (i >= 0) {
    ar.push((str[i - 2] || "") + (str[i - 1] || "") + (str[i] || ""));
    i = i - 3;
  }
  return ar.reverse().join(",");
}

export function currencyFormat(no) {
  if (no === null) {
    no = 0;
  }
  var ar = (+no).toFixed(2).split(".");
  return [numberFormat(ar[0] | 0), ".", ar[1]].join("");
}

export function translateAuto(key) {
  return i18next.t(key);
}

export function ExportAs(List, Type, Entity) {
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}

function returncsv(values) {
  var json = values;
  var fields = Object.keys(json[0]);
  var replacer = function(key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  return csv;
}

export const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);
export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function ConvertToDateCurrentTimeZone(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return dateConverted;
  }
}
export function ConvertToDateCurrentTimeZoneForNotInput(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, h:mm:ss A`);
  }
}
export function ConvertToDateCurrentTimeZoneDateTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, h:mm A`);
  }
}
export function ConvertToDateCurrentTimeZoneOnlyTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`h:mm A`);
  }
}
export function ConvertToDateCurrentTimeZoneOnlyDate(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(getFromatdate());
  }
}
export function MomentOnlyTime(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else return moment(ConvertToDateCurrentTimeZone(params)).format("HH:mm");
}

export function MomentOnlyDate(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else return moment(params).format(getFromatdate());
}

export function ConvertToDateGMT(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    let UTCDate = new Date(params).toUTCString();
    let DateFormater = moment.utc(UTCDate);
    return DateFormater;
  }
}
