import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";

function TagInput(props) {
  const handleInputChange = (e) => {
    props.setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const trimmedTag = props.inputValue.trim();
      if (trimmedTag !== "") {
        if (props.tags.includes(trimmedTag)) {
          // Replace the existing tag with the new one
          const updatedTags = props.tags.map(tag =>
            tag === trimmedTag ? trimmedTag : tag
          );
          props.setTags(updatedTags);
        } else {
          // Add the new tag to the tags array
          props.setTags([...props.tags, trimmedTag]);
        }
        props.setInputValue("");
      }
    }
  };

  const handleTagRemove = (tag) => {
    const newTags = props.tags.filter((t) => t !== tag);
    props.setTags(newTags);
  };

  const handleMouseOut = () => {
    const trimmedTag = props.inputValue.trim();
    if (trimmedTag !== "") {
      if (props.tags.includes(trimmedTag)) {
        // Replace the existing tag with the new one
        const updatedTags = props.tags.map(tag =>
          tag === trimmedTag ? trimmedTag : tag
        );
        props.setTags(updatedTags);
      } else {
        // Add the new tag to the tags array
        props.setTags([...props.tags, trimmedTag]);
      }
      props.setInputValue("");
    }
  };

  return (
    <div>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label className="required">Authorized Person</Form.Label>{" "}
        <Form.Control
          type="text"
          ref={props.inputRef}
          value={props.inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Add authorized person"
          onMouseOut={handleMouseOut}
        />
      </Form.Group>
      <div className="d-flex flex-wrap">
        {props.tags.map((tag) => (
          <div className="d-flex align-items-center mt-2 mb-4" key={tag}>
            <span className="badge badge-secondary d-flex align-items-center mr-3">
              {tag}
              <i
                onClick={() => handleTagRemove(tag)}
                className="fa fa-times ml-3 cursor-pointer"
              ></i>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TagInput;
