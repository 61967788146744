import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import Pharmacies from "./modules/Pharmacies/Pharmacies";
import RxDropPage from "./modules/RxDrop/RxDrop";


export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/pharmacies" />
        }
        <ContentRoute path="/pharmacies" component={Pharmacies} />
        <ContentRoute path="/rxdrop/:id" component={RxDropPage} />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
