import React, { useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";

const CheckoutForm = ({ handleSubmit2, formik }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
console.log("in")
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      // Handle the case where the card element is missing
      return;
    }

    const result = await stripe.createToken(cardElement, {
      name: formik.values.cardName,
    });

    if (result.error) {
      Swal.fire({
        title: "Error!",
        text: result.error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error(result.error.message);
    } else {
      console.log("token resula", result);
      if (result.token?.id) {
        formik.values.cardToken = result.token?.id;
        formik.values.cardId = result.token?.card?.id;
        handleSubmit2(formik.values);
      }
    }
  };

  const cardElementOptions = {
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="custom-card-element-container"
        style={{
          display: "block",
          width: "100%",
          padding: "0.975rem 1rem",
          fontSize: "1.1rem",
          fontWeight: 500,
          lineHeight: 1.5,
          color: "#5e6278",
          backgroundColor: "#ffffff",
          backgroundClip: "padding-box",
          border: "#ced4da 1px solid",
          borderRadius: "0.475rem",
          boxShadow: "none",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          outline: "none",
        }}
      >
        <CardElement
          options={cardElementOptions}
          className="custom-card-element"
        />
      </div>
      <button
        id="pay_now"
        hidden={true}
        className="btn btn-sm btn-primary mt-5"
        type="button"
        onClick={handleSubmit}
      >
        Pay Now
      </button>
    </form>
  );
};

export default CheckoutForm;
